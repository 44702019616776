import Image from '@atoms/Image';
import Text from '@atoms/Text';
import texts from '@components/Homepage/en.json';
import ExploreOpportunitiesButton from '../ExploreOpportunitiesButton';

const InvestmentJourney = ({ onButtonClick, ctaButtonText }) => {
    return (
        <div className="relative pt-[64px] pb-9 px-4 md:pb-0 md:px-0 md:pt-[314px] flex flex-col items-center">
            <Image
                src="/images/homepage/jiraaf-mascot-2.webp"
                alt="Jiraaf-mascot"
                className="hidden md:block w-[264px] h-[248px] absolute top-[6.25rem]"
            />
            <Image
                src="/images/homepage/JiraafMascot.webp"
                alt="Jiraaf-mascot"
                className="md:hidden w-[244px] h-[394px] absolute -right-16 top-2"
            />
            <div className="flex flex-col items-start w-full md:items-center md:bg-primary-70 md:py-10">
                <Text
                    content={texts?.StartYourInvestment}
                    className="text-primary-500 h4-semibold md:h3-semibold text-left md:text-center max-w-[240px] md:max-w-none"
                    htmlTag='h2'
                />
                <ExploreOpportunitiesButton
                    onButtonClick={onButtonClick}
                    className="mt-4 xl:mt-6"
                    gtmTag="gtm-click-explore-opportunities-btn-in-homepage"
                    ctaButtonText={ctaButtonText}
                />
            </div>
            <Image
                src="/images/homepage/orange-skeleton.svg"
                className="absolute -left-5 -top-12 rotate-180 md:rotate-0 md:top-10 xl:top-4 xl:-left-12 h-[74px] md:h-[111px] xl:h-[148px]"
            />
        </div>
    );
};

export default InvestmentJourney;